<template>
  <div class="deductionAuth">
    <mt-header :title="$t('deductionAuth')">
      <router-link v-if="user.deductionAuth == 'Y'" to slot="left">
        <mt-button icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <router-link v-else to="/" slot="left">
        <mt-button icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="title">{{ $t('cardVerificationStatus') }}</div>
    <div v-if="user.deductionAuth == 'Y'" class="status verified">
      {{ $t('verified') }}
    </div>
    <div v-else class="status">
      {{ $t('notVerified') }}
    </div>
    <div class="tips">
      {{ $t('deductionAuthTips') }}
    </div>
    <div class="bottom">

      <mt-button v-if="user.deductionAuth == 'Y'" type="primary" size="large" plain @click="$router.go(-1)">{{
        $t("stepBack")
      }}</mt-button>
      <mt-button v-else type="primary" size="large" plain @click="onDeductionAuth">{{
        $t("validateCard")
      }}</mt-button>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { mapState, mapMutations } from "vuex";
import { getUserInfo, getPayPlatAddress } from '../../utils/api'
export default {
  name: 'DeductionAuth',
  components: {
    callPhone,
  },
  data () {
    return {
      
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await Promise.all([
        this.getUser(),
      ])
      this.$indicator.close();
    },
    // 获取代扣地址
    async onDeductionAuth() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.$axios({
        method: "post",
        url: getPayPlatAddress,
        data: {
          applyId: this.user.applyId,
          payType: 'LINK_BINDING_CARD',
        }
      }).then((e) => {
        if (e.status.code == "000") {
          window.location.href = e.body.webUrl
        }
      }).catch(() => {})
      this.$indicator.close();
    },
    async getUser() {
      await this.$axios({
        method: "post",
        url: getUserInfo,
      }).then((e) => {
        if (e.status.code == "000") {
          this.SET_USER(e.body);
        }
      }).catch(() => {})
    },
  }
}
</script>

<style lang="scss" scoped>
.deductionAuth {
  text-align: center;
  .title {
    color: #67c23a;
    margin: 50px 20px 10px 20px;
    font-size: 18px;
    font-weight: 600;
  }
  .status {
    display: inline-block;
    padding: 5px 10px;
    background-color: #d0d0d0;
    color: white;
    border-radius: 10px;
    margin: 0 auto;
  }
  .verified {
    background-color: #67c23a;
  }
  .tips {
    padding: 30px 40px 10px 40px;
    text-align: left;
  }
  .bottom {
    padding: 60px 20px 20px 20px;
  }
}
</style>
